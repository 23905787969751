import { createSlice } from '@reduxjs/toolkit'
export const PrivacyReducer = createSlice({
  name: 'privacy',
  initialState: {
    privacy: false
  },
  reducers: {
    updatePrivacy: (state, action) => {
      if (action.payload.privacy) {
        state.privacy = action.payload.privacy
      } else {
        state.privacy = action.payload.privacy
      }
    }
  }
})
export const { updatePrivacy } = PrivacyReducer.actions
export default PrivacyReducer.reducer

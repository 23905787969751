import PropTypes from 'prop-types'
import Avatar from '@components/avatar'
import { X } from 'react-feather'
import toast from 'react-hot-toast'

export const ToastContent = ({ t, name, icon, color, msg, type }) => {
  return (
    <div
      className={
        type === 1 ? 'd-flex align-items-center' : 'd-flex align-items-baseline'
      }
      style={{ maxWidth: '100%' }}
    >
      <div className="me-1">
        <Avatar size="sm" color={color} icon={icon} />
      </div>
      <div className="d-flex flex-column" style={{ flex: 1 }}>
        <div className="d-flex justify-content-between" style={{ flex: 1 }}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              boxSizing: 'border-box',
              position: 'relative',
              flex: 1,
              marginRight: '5px',
              maxWidth: '240px'
            }}
          >
            <h6
              className={`diagnosis-tab ${name.length > 35 ? 'animate-it' : ''}`}
            >
              {name}
            </h6>
          </div>
          <X
            size={17}
            className="pointer "
            onClick={() => toast.dismiss(t.id)}
            style={{ flexShrink: 0 }}
          />
        </div>
        <span style={{ overflowWrap: 'break-word' }}>{msg}</span>
      </div>
    </div>
  )
}

ToastContent.propTypes = {
  t: PropTypes.object,
  name: PropTypes.string,
  icon: PropTypes.element,
  color: PropTypes.string,
  msg: PropTypes.string
}

/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** components
import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
import { updateUser } from '../../../authentication/authSlice'
// Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name={key}
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.detail ||
            errors[key][0] ||
            errors?.non_field_errors?.[0] ||
            errors.msg
          }
        />
      ))
    })
  }
}

// ** Get  Provider Profile
export const getProviderProfileAction = createAsyncThunk(
  'providerProfile/getProviderProfileAction',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.getProviderProfile()
      const oldProfile = JSON.parse(localStorage.getItem('userData'))
      const updatedProfile = response?.data
      let newProfile = {}
      for (const key in oldProfile) {
        if (updatedProfile.hasOwnProperty(key)) {
          newProfile[key] = updatedProfile[key]
        }
      }
      newProfile = {
        ...oldProfile,
        ...newProfile
      }

      localStorage.setItem('userData', JSON.stringify(newProfile))
      dispatch(updateUser())
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get  Provider Profile
export const updateProviderImageAction = createAsyncThunk(
  'provider/updateImage',
  async ({ id, image, callback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.updateProviderImage(id, image)
      toast((t) => (
        <ToastContent
          t={t}
          name="Provider Image Update Successfully"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      if (response?.data) {
        localStorage.setItem('avatar', response?.data?.avatar)
        callback()
      }

      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Delete  Provider Profile
export const deleteProviderImageAction = createAsyncThunk(
  'provider/deleteImage',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.deleteProviderImage(id)
      toast((t) => (
        <ToastContent
          t={t}
          name="Provider Image Deleted Successfully"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      if (response?.data) {
        localStorage.removeItem('avatar')
      }

      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get  Provider Profile
export const updateProviderProfileAction = createAsyncThunk(
  'providerProfile/updateProviderProfileAction',
  async (
    { id, data, image, navigate, callback },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await useJwt.updateProviderProfile(id, data)
      if (!!(image && id)) {
        dispatch(updateProviderImageAction({ id, image, callback }))
      }
      if (response?.data && !(image && id)) {
        toast((t) => (
          <ToastContent
            t={t}
            name="Provider Updated Successfully"
            icon={<Check size={14} />}
            color="success"
            msg={response?.data?.message}
          />
        ))
      }
      const oldProfile = JSON.parse(localStorage.getItem('userData'))
      const updatedProfile = response?.data
      let newProfile = {}
      for (const key in oldProfile) {
        if (updatedProfile.hasOwnProperty(key)) {
          newProfile[key] = updatedProfile[key]
        }
      }
      newProfile = {
        ...oldProfile,
        ...newProfile
      }

      localStorage.setItem('userData', JSON.stringify(newProfile))
      dispatch(updateUser())
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const update2faAction = createAsyncThunk(
  'provider/update2fa',
  async ({ data, callback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.update2fa(data)
      toast((t) => (
        <ToastContent
          t={t}
          name="Success"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.msg}
        />
      ))
      callback()
      dispatch(getProviderProfileAction())
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error"
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.msg)
    }
  }
)

// ** React Imports
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { useSelector, useDispatch } from 'react-redux'
import { Badge } from 'reactstrap'
import { updatePrivacy } from '../../../../../redux/privacy/privacySlice'
const VerticalNavMenuLink = ({ item, activeItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink
  // ** Hooks
  const { t } = useTranslation()

  const { getAllNotification } = useSelector((state) => state.notification)
  const dispatch = useDispatch()
  const rows = getAllNotification?.data

  const unreadNotificationNumber = rows?.filter(
    (item) => item.read === false
  )?.length

  return (
    <li
      className={classnames({
        'nav-item': !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      {item?.title !== 'Privacy' && (
        <LinkTag
          className={`d-flex align-items-center text-clip`}
          target={item.newTab ? '_blank' : undefined}
          /*eslint-disable */
          {...(item.externalLink === true
            ? {
                href: item.navLink || '/'
              }
            : {
                to: item.navLink || '/',
                className: ({ isActive }) => {
                  if (isActive && !item.disabled) {
                    return 'd-flex align-items-center active'
                  }
                }
              })}
          onClick={(e) => {
            if (
              item.button ||
              item.navLink.length === 0 ||
              item.navLink === '#' ||
              item.disabled === true
            ) {
              e.preventDefault()
            }
          }}
        >
          {item.icon}
          <span className="menu-item text-truncate pr-1rem">
            {t(item.title)}
          </span>
          {unreadNotificationNumber > 0 && item.id === 'notifications' && (
            <Badge className=" notification-badge" color={'danger'} pill>
              {unreadNotificationNumber}
            </Badge>
          )}

          {/* {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null} */}
        </LinkTag>
      )}
      {item?.title === 'Privacy' && (
        <div className="d-flex align-items-center text-clip toggle-checkbox">
          {item.icon}
          <span className="menu-item text-truncate">{t(item.title)}</span>
          <div class="checkbox_item citem_1">
            <label class="checkbox_wrap">
              <input
                type="checkbox"
                name="checkbox"
                class="checkbox_inp"
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    dispatch(updatePrivacy({ privacy: true }))
                  } else {
                    dispatch(updatePrivacy({ privacy: false }))
                  }
                }}
              ></input>
              <span class="checkbox_mark"></span>
            </label>
          </div>
        </div>
      )}
    </li>
  )
}

export default VerticalNavMenuLink

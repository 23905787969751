/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteProviderImageAction,
  getProviderProfileAction,
  updateProviderImageAction,
  updateProviderProfileAction,
  update2faAction
} from './myProfileActions'

export const providerProfileReducer = createSlice({
  name: 'providerProfile',
  initialState: {
    loading: false,
    updateLoading: false,
    loadingImage: false,
    getProviderProfile: null,
    updateProviderProfile: null,
    deleteLoadingImage: false,
    twoFaUpdateLoading: false
  },
  reducers: {
    resetGetProviderProfile: (state) => {
      state.getProviderProfile = null
    }
  },
  extraReducers: (builder) => {
    const success = (state) => {
      state.loading = false
      state.error = null
    }

    const error = (state, action) => {
      state.loading = false
      state.error = action.payload
    }

    builder

      //** Get Provider Profile */
      .addCase(getProviderProfileAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getProviderProfileAction.fulfilled, (state, action) => {
        success(state)
        state.getProviderProfile = action.payload
      })
      .addCase(getProviderProfileAction.rejected, (state, action) => {
        error(state, action)
        state.getProviderProfile = null
      })

      //** Update Provider Profile */
      .addCase(updateProviderProfileAction.pending, (state) => {
        state.updateLoading = true
      })
      .addCase(updateProviderProfileAction.fulfilled, (state, action) => {
        state.updateLoading = false
        state.getProviderProfile = action.payload
        state.error = null
      })
      .addCase(updateProviderProfileAction.rejected, (state, action) => {
        state.updateLoading = false
        state.error = action.payload
        state.getProviderProfile = null
      })

      //** Update Provider Image */
      .addCase(updateProviderImageAction.pending, (state) => {
        state.loadingImage = true
      })
      .addCase(updateProviderImageAction.fulfilled, (state, action) => {
        state.imageError = null
        state.loadingImage = false
        state.getProviderProfile = {
          ...state.getProviderProfile,
          avatar: action.payload.avatar
        }
      })
      .addCase(updateProviderImageAction.rejected, (state, action) => {
        state.loadingImage = false
        state.uploadImageError = action.payload
      })
      //** Delete Provider Image */
      .addCase(deleteProviderImageAction.pending, (state) => {
        state.deleteLoadingImage = true
      })
      .addCase(deleteProviderImageAction.fulfilled, (state, action) => {
        state.imageError = null
        state.deleteLoadingImage = false
        state.getProviderProfile = { ...state.getProviderProfile, avatar: null }
      })
      .addCase(deleteProviderImageAction.rejected, (state, action) => {
        state.deleteLoadingImage = false
      })
      .addCase(update2faAction.pending, (state) => {
        state.twoFaUpdateLoading = true
      })
      .addCase(update2faAction.fulfilled, (state, action) => {
        state.twoFaUpdateLoading = false
      })
      .addCase(update2faAction.rejected, (state, action) => {
        state.twoFaUpdateLoading = false
      })
  }
})

export const { resetGetProviderProfile } = providerProfileReducer.actions
export default providerProfileReducer.reducer

import { createSlice } from '@reduxjs/toolkit'

import { getAllActivitiesAction } from './activityAction'

const ActivityReducer = createSlice({
  name: 'activity',
  initialState: {
    activityPending: false,

    getAllActivities: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    },

    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ** Get all activities
      .addCase(getAllActivitiesAction.pending, (state) => {
        state.activityPending = true
      })
      .addCase(getAllActivitiesAction.fulfilled, (state, action) => {
        state.activityPending = false
        state.getAllActivities.data = action.payload?.result
        state.getAllActivities.offset = action.payload?.offset
        state.getAllActivities.limit = action.payload?.limit
        state.getAllActivities.count = action.payload?.count
      })
      .addCase(getAllActivitiesAction.rejected, (state, action) => {
        state.activityPending = false
        state.getAllActivities = {
          data: [],
          offset: 0,
          limit: 10,
          total: 0
        }
        state.error = action.payload
      })
  }
})

export default ActivityReducer.reducer

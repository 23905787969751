import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
//** GET ALL Activities */
export const getAllActivitiesAction = createAsyncThunk(
  'activities/getAllActivities',
  async (
    { offset, limit, startDate, endDate, time, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await useJwt.getAllActivities({
        offset,
        limit,
        startDate,
        endDate,
        time,
        search
      })

      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Activities'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Handling Pagination
export const handlePageChange = createAsyncThunk(
  'activities/handlePageChange',
  async ({ offset, limit, startDate, endDate, time, search }, { dispatch }) => {
    dispatch(
      getAllActivitiesAction({
        offset,
        limit,
        search,

        startDate,
        endDate,
        time,
        callback: () => {}
      })
    )
  }
)

// ** Handling Limit
export const handleLimitChange = createAsyncThunk(
  'activities/handleLimitChange',
  async (
    { oldLimit, newLimit, search, startDate, endDate, time },
    { dispatch }
  ) => {
    if (newLimit !== oldLimit) {
      dispatch(
        getAllActivitiesAction({
          offset: 0,
          limit: newLimit,
          time,
          search,
          startDate,
          endDate
        })
      )
    }
  }
)

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  BillingInvoiceAction,
  SuperBillingInvoiceAction,
  createClientInvoiceAction,
  deleteAdminNoteAction,
  deleteClientAction,
  deleteClientDocumentsAction,
  deleteClientInvoiceAction,
  deleteSuperBillInvoiceAction,
  editSuperBillInvoiceAction,
  generateSuperBillingInvoiceAction,
  getAllClientBillingAction,
  getAllClientBillingWithoutSuperbillAction,
  getAllClientDocumentsAction,
  getAllClientsAction,
  getAllIntakeFormAction,
  getClientAction,
  getClientAppointmentsAction,
  getClientBillingAction,
  getClientDetailsAction,
  getClientInvoiceAction,
  getClientNotesAction,
  getMessageDetailsAction,
  getSuperBillInvoiceAction,
  registerClient,
  registerClientDocumentsAction,
  registerClientIntakeFormsAction,
  retriveAllClientDocumentsAction,
  updateClientAction,
  updateClientAdminNoteAction,
  updateClientBillingAction,
  updateClientImageAction,
  uploadInsuranceCardImageAction,
  exportClientDetailsAction
} from './clientAction'

export const ClientReducer = createSlice({
  name: 'client',
  initialState: {
    loading: false,
    loadingImage: false,
    updateLoading: false,
    deleteLoading: false,
    clientNotesPending: false,
    clientAppointmentsPending: false,
    getClientBillingLoading: false,
    updateClientBillingLoading: false,
    deleteClientInvoiceLoading: false,
    createClientInvoiceLoading: null,
    getAllClientDocumentsLoading: false,
    editSuperBillingInvoiceEmailLoading: false,
    getAllClientBillingLoading: false,
    getClientInvoiceLoading: false,
    registerDocumentLoading: false,
    deleteDocumentLoading: false,
    clientIntakeFormPending: false,
    deleteAdminNoteLoading: false,
    updateAdminNoteLoading: false,
    BillingInvoiceLoading: false,
    getClientMessageLoading: false,
    getClientDetailsLoading: false,
    registerClientIntakeFormsLoading: false,
    superBillingInvoiceEmailLoading: false,
    superBillingInvoiceLoading: false,
    deleteSuperBillingInvoiceEmailLoading: false,
    getSuperBillingInvoiceEmailLoading: false,
    getAllClientBillingWithoutSuperbillDataLoading: false,
    getAllClientsData: {
      clientsList: [],
      count: 0,
      offset: 0,
      limit: 10
    },
    getAllClientBillingData: {
      clientBillingList: [],
      count: 0,
      offset: 0,
      limit: 10
    },
    getAllClientBillingWithoutSuperbillData: {
      clientBillingList: [],
      count: 0,
      offset: 0,
      limit: 10
    },
    clientNotes: [],
    getClient: null,
    imageError: null,
    updateClient: null,
    registerClient: null,
    clientAppointments: {
      limit: 10,
      offset: 0,
      count: 0,
      data: []
    },
    getAllClientIntakeForm: {
      limit: 10,
      offset: 0,
      count: 0,
      data: []
    },
    uploadImageError: null,
    updateImageError: null,
    getClientBilling: null,
    getClientInvoice: null,
    superBillingInvoice: null,
    getClientMessages: {
      data: []
    },

    createClientInvoice: null,
    updateClientBilling: null,
    registerClientDocuments: null,
    getAllClientDocumentsData: null,
    getSuperBillingInvoiceData: null,
    exportClientDetailsLoading: false,
    error: null
  },
  reducers: {
    addInvoicesToSuperbill: (state, action) => {
      state.getSuperBillingInvoiceData.client_invoices = action.payload
    },
    removeInvoicesFromSuperbill: (state, action) => {
      state.getSuperBillingInvoiceData.client_invoices =
        state.getSuperBillingInvoiceData.client_invoices.filter((element) => {
          return element.id !== action.payload.id
        })
    },
    resetSuperbillData: (state) => {
      state.getSuperBillingInvoiceData = null
    },
    resetGetClientInvoice: (state) => {
      state.getClientInvoice = null
    },
    resetGetAllClients: (state) => {
      state.getAllClientsData = {
        clientsList: [],
        count: 0,
        offset: 0,
        limit: 10
      }
    },
    resetGetSuperBillInvoice: (state) => {
      state.superBillingInvoice = null
    },
    resetGetAllClientBilling: (state) => {
      state.getAllClientBillingData = {}
    },

    resetGetClient: (state) => {
      state.getClient = null
    },
    resetRegisterClient: (state) => {
      state.registerClient = {}
    },
    resetUpdateClient: (state) => {
      state.updateClient = {}
    },
    resetClientAppointments: (state) => {
      state.clientAppointments = {
        limit: 10,
        offset: 0,
        data: []
      }
    },
    resetClientNotes: (state) => {
      state.clientNotes = []
    },
    resetClientBilling: (state) => {
      state.getClientBilling = []
    }
  },
  extraReducers: (builder) => {
    const success = (state) => {
      state.loading = false
      state.error = null
    }

    const error = (state, action) => {
      state.loading = false
      state.error = action.payload
    }

    builder

      //**  Register Client */
      .addCase(registerClient.pending, (state) => {
        state.loading = true
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        success(state)
        state.registerClient = action.payload
      })
      .addCase(registerClient.rejected, (state, action) => {
        error(state, action)
        state.registerClient = null
      })

      //**  Register Client Documents */
      .addCase(registerClientDocumentsAction.pending, (state) => {
        state.registerDocumentLoading = true
      })
      .addCase(registerClientDocumentsAction.fulfilled, (state, action) => {
        state.registerDocumentLoading = false
        state.registerClientDocuments = action.payload
        state.error = null
      })
      .addCase(registerClientDocumentsAction.rejected, (state, action) => {
        state.registerDocumentLoading = false
        state.error = action.payload
        state.registerClientDocuments = null
      })

      // get all  Client documents
      .addCase(getAllClientDocumentsAction.pending, (state) => {
        state.getAllClientDocumentsLoading = true
      })
      .addCase(getAllClientDocumentsAction.fulfilled, (state, action) => {
        state.getAllClientDocumentsLoading = false
        state.getAllClientDocumentsData = action.payload
        state.error = null
      })
      .addCase(getAllClientDocumentsAction.rejected, (state, action) => {
        state.getAllClientDocumentsLoading = false
        state.getAllClientDocumentsData = null
        state.error = action.payload
      })

      // get all  Client documents
      .addCase(retriveAllClientDocumentsAction.pending, (state) => {
        state.getAllClientDocumentsLoading = true
      })
      .addCase(retriveAllClientDocumentsAction.fulfilled, (state, action) => {
        state.getAllClientDocumentsLoading = false
        state.getAllClientDocumentsData = action.payload
        state.error = null
      })
      .addCase(retriveAllClientDocumentsAction.rejected, (state, action) => {
        state.getAllClientDocumentsLoading = false
        state.getAllClientDocumentsData = null
        state.error = action.payload
      })

      // Update Client
      .addCase(updateClientAction.pending, (state) => {
        state.updateLoading = true
      })
      .addCase(updateClientAction.fulfilled, (state, action) => {
        state.updateLoading = false
        state.updateClient = action.payload
        state.error = null
      })
      .addCase(updateClientAction.rejected, (state, action) => {
        state.updateLoading = false
        state.updateClient = null
        state.error = action.payload
      })

      // update image
      .addCase(updateClientImageAction.pending, (state) => {
        state.loadingImage = true
      })
      .addCase(updateClientImageAction.fulfilled, (state) => {
        state.imageError = null
        state.loadingImage = false
      })
      .addCase(updateClientImageAction.rejected, (state, action) => {
        state.loadingImage = false
        state.updateImageError = action.payload
      })

      // upload Insurance image
      .addCase(uploadInsuranceCardImageAction.pending, (state) => {
        state.loadingImage = true
      })
      .addCase(uploadInsuranceCardImageAction.fulfilled, (state) => {
        state.uploadImageError = null
        state.loadingImage = false
      })
      .addCase(uploadInsuranceCardImageAction.rejected, (state, action) => {
        state.loadingImage = false
        state.uploadImageError = action.payload
      })

      //**  Get All Clients */
      .addCase(getAllClientsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllClientsAction.fulfilled, (state, action) => {
        success(state)
        state.getAllClientsData.clientsList = action.payload?.result
        state.getAllClientsData.count = action.payload?.count
        state.getAllClientsData.offset = action.payload?.offset
        state.getAllClientsData.limit = action.payload?.limit
      })
      .addCase(getAllClientsAction.rejected, (state, action) => {
        error(state, action)
        state.getAllClientsData.providersList = []
        state.getAllClientsData.count = 0
      })

      //** Get Client */
      .addCase(getClientAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getClientAction.fulfilled, (state, action) => {
        success(state)
        state.getClient = action.payload
      })
      .addCase(getClientAction.rejected, (state, action) => {
        error(state, action)
        state.getClient = null
      })

      //**  get  Client invoice */
      .addCase(getClientInvoiceAction.pending, (state) => {
        state.getClientInvoiceLoading = true
      })
      .addCase(getClientInvoiceAction.fulfilled, (state, action) => {
        state.getClientInvoiceLoading = false
        state.getClientInvoice = action.payload
      })
      .addCase(getClientInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.getClientInvoice = []
        state.getClientInvoiceLoading = false
      })

      //**  get  Client Details In CSV FIle */
      .addCase(getClientDetailsAction.pending, (state) => {
        state.getClientDetailsLoading = true
      })
      .addCase(getClientDetailsAction.fulfilled, (state, action) => {
        state.getClientDetailsLoading = false
      })
      .addCase(getClientDetailsAction.rejected, (state, action) => {
        state.error = action.payload

        state.getClientDetailsLoading = false
      })

      //**  get Client message Detail */
      .addCase(getMessageDetailsAction.pending, (state) => {
        state.getClientMessageLoading = true
      })
      .addCase(getMessageDetailsAction.fulfilled, (state, action) => {
        state.getClientMessageLoading = false
        state.getClientMessages = action.payload
      })
      .addCase(getMessageDetailsAction.rejected, (state, action) => {
        state.error = action.payload
        state.getClientMessages = []
        state.getClientMessageLoading = false
      })

      //** Delete Client */
      .addCase(deleteClientAction.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteClientAction.fulfilled, (state, action) => {
        state.deleteLoading = false
        state.error = null
      })
      .addCase(deleteClientAction.rejected, (state, action) => {
        state.deleteLoading = false
        state.error = action.payload
      })

      //** Delete Client Document*/
      .addCase(deleteClientDocumentsAction.pending, (state) => {
        state.deleteDocumentLoading = true
      })
      .addCase(deleteClientDocumentsAction.fulfilled, (state, action) => {
        state.deleteDocumentLoading = false
        state.error = null
      })
      .addCase(deleteClientDocumentsAction.rejected, (state, action) => {
        state.deleteDocumentLoading = false
        state.error = action.payload
      })

      //**  Get All Client Billings */
      .addCase(getAllClientBillingAction.pending, (state) => {
        state.getAllClientBillingLoading = true
      })
      .addCase(getAllClientBillingAction.fulfilled, (state, action) => {
        state.getAllClientBillingLoading = false
        state.getAllClientBillingData.clientBillingList = action.payload?.result
        state.getAllClientBillingData.count = action.payload?.count
        state.getAllClientBillingData.offset = action.payload?.offset
        state.getAllClientBillingData.limit = action.payload?.limit
      })
      .addCase(getAllClientBillingAction.rejected, (state, action) => {
        state.getAllClientBillingLoading = false
        state.getAllClientBillingData.clientBillingList = []
        state.getAllClientBillingData.count = 0
        state.error = action.payload
      })
      .addCase(getAllClientBillingWithoutSuperbillAction.pending, (state) => {
        state.getAllClientBillingWithoutSuperbillDataLoading = true
      })
      .addCase(
        getAllClientBillingWithoutSuperbillAction.fulfilled,
        (state, action) => {
          state.getAllClientBillingWithoutSuperbillDataLoading = false
          state.getAllClientBillingWithoutSuperbillData.clientBillingList =
            action.payload?.result
          state.getAllClientBillingWithoutSuperbillData.count =
            action.payload?.count
          state.getAllClientBillingWithoutSuperbillData.offset =
            action.payload?.offset
          state.getAllClientBillingWithoutSuperbillData.limit =
            action.payload?.limit
        }
      )
      .addCase(
        getAllClientBillingWithoutSuperbillAction.rejected,
        (state, action) => {
          state.getAllClientBillingWithoutSuperbillDataLoading = false
          state.getAllClientBillingWithoutSuperbillData.clientBillingList = []
          state.getAllClientBillingWithoutSuperbillData.count = 0
          state.error = action.payload
        }
      )
      //**  Get  Client Billings */
      .addCase(getClientBillingAction.pending, (state) => {
        state.getClientBillingLoading = true
      })
      .addCase(getClientBillingAction.fulfilled, (state, action) => {
        state.getClientBillingLoading = false
        state.getClientBilling = action.payload
      })
      .addCase(getClientBillingAction.rejected, (state, action) => {
        state.error = action.payload
        state.getClientBilling = []
        state.getClientBillingLoading = false
      })

      //**  Update  Client Billings */
      .addCase(updateClientBillingAction.pending, (state) => {
        state.updateClientBillingLoading = true
      })
      .addCase(updateClientBillingAction.fulfilled, (state, action) => {
        state.updateClientBillingLoading = false
        state.updateClientBilling = action.payload
      })
      .addCase(updateClientBillingAction.rejected, (state, action) => {
        state.error = action.payload
        state.updateClientBilling = []
        state.updateClientBillingLoading = false
      })

      // ** get Client Appointments
      .addCase(getClientAppointmentsAction.pending, (state) => {
        state.clientAppointmentsPending = true
      })
      .addCase(getClientAppointmentsAction.fulfilled, (state, action) => {
        state.clientAppointmentsPending = false
        state.clientAppointments.data = action.payload.result
        state.clientAppointments.offset = action.payload.offset
        state.clientAppointments.limit = action.payload.limit
        state.clientAppointments.count = action.payload.count
      })
      .addCase(getClientAppointmentsAction.rejected, (state, action) => {
        state.error = action.payload
        state.clientAppointments = {
          limit: 10,
          offset: 0,
          data: []
        }
        state.clientAppointmentsPending = false
      })

      // ** Register Client Invoice
      .addCase(createClientInvoiceAction.pending, (state) => {
        state.createClientInvoiceLoading = true
      })
      .addCase(createClientInvoiceAction.fulfilled, (state, action) => {
        state.createClientInvoiceLoading = false
        state.createClientInvoice = action.payload?.result
      })
      .addCase(createClientInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.createClientInvoice = []
        state.createClientInvoiceLoading = false
      })

      //**  Get Client Notes
      .addCase(getClientNotesAction.pending, (state) => {
        if (state.clientNotes?.length === 0) {
          state.clientNotesPending = true
        }
      })
      .addCase(getClientNotesAction.fulfilled, (state, action) => {
        success(state)

        state.clientNotes = action.payload.result
        state.clientNotesPending = false
      })
      .addCase(getClientNotesAction.rejected, (state, action) => {
        error(state, action)
        state.clientNotes = []
        state.clientNotesPending = false
        state.error = action.payload
      })

      //**   get all client intake form  */
      .addCase(getAllIntakeFormAction.pending, (state) => {
        state.clientIntakeFormPending = true
      })
      .addCase(getAllIntakeFormAction.fulfilled, (state, action) => {
        success(state)
        state.getAllClientIntakeForm.data = action.payload?.result
        state.getAllClientIntakeForm.count = action.payload?.count
        state.getAllClientIntakeForm.offset = action.payload?.offset
        state.getAllClientIntakeForm.limit = action.payload?.limit
        state.clientIntakeFormPending = false
      })
      .addCase(getAllIntakeFormAction.rejected, (state, action) => {
        error(state, action)
        state.getAllClientIntakeForm = []
        state.clientIntakeFormPending = false
        state.error = action.payload
      })

      //** Register client intake form */
      .addCase(registerClientIntakeFormsAction.pending, (state) => {
        state.registerClientIntakeFormsLoading = true
      })
      .addCase(registerClientIntakeFormsAction.fulfilled, (state, action) => {
        state.registerClientIntakeFormsLoading = false
        state.error = null
      })
      .addCase(registerClientIntakeFormsAction.rejected, (state, action) => {
        state.error = action.payload
        state.registerClientIntakeFormsLoading = false
      })

      // ** Delete Client Invoice
      .addCase(deleteClientInvoiceAction.pending, (state) => {
        state.deleteClientInvoiceLoading = true
      })
      .addCase(deleteClientInvoiceAction.fulfilled, (state, action) => {
        state.deleteClientInvoiceLoading = false
        state.error = null
      })
      .addCase(deleteClientInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.deleteClientInvoiceLoading = false
      })

      // ** Delete Client admin note
      .addCase(deleteAdminNoteAction.pending, (state) => {
        state.deleteAdminNoteLoading = true
      })
      .addCase(deleteAdminNoteAction.fulfilled, (state, action) => {
        state.deleteAdminNoteLoading = false
        state.error = null
      })
      .addCase(deleteAdminNoteAction.rejected, (state, action) => {
        state.error = action.payload
        state.deleteAdminNoteLoading = false
      })

      // ** update Client admin note
      .addCase(updateClientAdminNoteAction.pending, (state) => {
        state.updateAdminNoteLoading = true
      })
      .addCase(updateClientAdminNoteAction.fulfilled, (state, action) => {
        state.updateAdminNoteLoading = false

        state.error = null
      })
      .addCase(updateClientAdminNoteAction.rejected, (state, action) => {
        state.error = action.payload
        state.updateAdminNoteLoading = false
      })
      // ** update Client admin note
      .addCase(BillingInvoiceAction.pending, (state) => {
        state.BillingInvoiceLoading = true
      })
      .addCase(BillingInvoiceAction.fulfilled, (state, action) => {
        state.BillingInvoiceLoading = false

        state.error = null
      })
      .addCase(BillingInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.BillingInvoiceLoading = false
      })

      // ** generate super bill invoice
      .addCase(SuperBillingInvoiceAction.pending, (state) => {
        state.superBillingInvoiceLoading = true
      })
      .addCase(SuperBillingInvoiceAction.fulfilled, (state, action) => {
        state.superBillingInvoiceLoading = false
        state.superBillingInvoice = action.payload
        state.error = null
      })
      .addCase(SuperBillingInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.superBillingInvoiceLoading = false
      })

      // ** generate super bill invoice
      .addCase(generateSuperBillingInvoiceAction.pending, (state) => {
        state.superBillingInvoiceEmailLoading = true
      })
      .addCase(generateSuperBillingInvoiceAction.fulfilled, (state, action) => {
        state.superBillingInvoiceEmailLoading = false
        state.error = null
      })
      .addCase(generateSuperBillingInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.superBillingInvoiceEmailLoading = false
      })
      // ** edit super bill invoice
      .addCase(editSuperBillInvoiceAction.pending, (state) => {
        state.editSuperBillingInvoiceEmailLoading = true
      })
      .addCase(editSuperBillInvoiceAction.fulfilled, (state, action) => {
        state.editSuperBillingInvoiceEmailLoading = false
        state.error = null
      })
      .addCase(editSuperBillInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.editSuperBillingInvoiceEmailLoading = false
      })
      // ** delete super bill invoice
      .addCase(deleteSuperBillInvoiceAction.pending, (state) => {
        state.deleteSuperBillingInvoiceEmailLoading = true
      })
      .addCase(deleteSuperBillInvoiceAction.fulfilled, (state, action) => {
        state.deleteSuperBillingInvoiceEmailLoading = false
        state.error = null
      })
      .addCase(deleteSuperBillInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.deleteSuperBillingInvoiceEmailLoading = false
      })

      // ** get super bill invoice
      .addCase(getSuperBillInvoiceAction.pending, (state) => {
        state.getSuperBillingInvoiceEmailLoading = true
      })
      .addCase(getSuperBillInvoiceAction.fulfilled, (state, action) => {
        state.getSuperBillingInvoiceEmailLoading = false
        state.getSuperBillingInvoiceData = action.payload
        state.error = null
      })
      .addCase(getSuperBillInvoiceAction.rejected, (state, action) => {
        state.error = action.payload
        state.getSuperBillingInvoiceEmailLoading = false
        state.getSuperBillingInvoiceData = null
      })
      .addCase(exportClientDetailsAction.pending, (state) => {
        state.exportClientDetailsLoading = true
      })
      .addCase(exportClientDetailsAction.fulfilled, (state, action) => {
        state.exportClientDetailsLoading = false
      })
      .addCase(exportClientDetailsAction.rejected, (state, action) => {
        state.exportClientDetailsLoading = false
      })
  }
})

export const {
  addInvoicesToSuperbill,
  removeInvoicesFromSuperbill,
  resetGetClient,
  resetClientNotes,
  resetUpdateClient,
  resetClientBilling,
  resetGetAllClients,
  resetRegisterClient,
  resetClientAppointments,
  resetGetAllClientBilling,
  resetGetClientInvoice,
  resetGetSuperBillInvoice,
  resetSuperbillData
} = ClientReducer.actions

export default ClientReducer.reducer

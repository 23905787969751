/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  markClientMessagesAction,
  receiveMessageAction,
  sendMessageAction,
  getAllMessagesAction,
  getAllConversationsAction
} from './messageAction'

export const messagesReducer = createSlice({
  name: 'messages',
  initialState: {
    error: null,
    loading: false,
    sendMessages: null,
    markMessage: false,
    getAllMessages: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    },
    getAllConversations: {
      data: []
    },
    receiveLoading: false
  },
  reducers: {
    newMessagesHandler: (state, action) => {
      state.getAllMessages.data.push(action.payload)
    },
    resetReceiveMessages: (state) => {
      state.getAllMessages = {
        data: [],
        offset: 0,
        limit: 10,
        count: 0
      }
    }
  },

  extraReducers: (builder) => {
    builder

      // ** Send Messages
      .addCase(sendMessageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sendMessageAction.fulfilled, (state, action) => {
        state.loading = false
        state.sendMessages = action.payload
        state.error = null
        state.getAllMessages.data = action.payload?.recievedRes?.result
        state.getAllMessages.offset = action.payload?.recievedRes?.offset
        state.getAllMessages.limit = action.payload?.recievedRes?.limit
        state.getAllMessages.count = action.payload?.recievedRes?.count
      })
      .addCase(sendMessageAction.rejected, (state, action) => {
        state.loading = false
        state.sendMessages = {}
        state.error = action.payload
      })

      // ** Receive Message Messages
      .addCase(receiveMessageAction.pending, (state) => {
        state.receiveLoading = true
      })
      .addCase(receiveMessageAction.fulfilled, (state, action) => {
        state.receiveLoading = false
        state.getAllMessages.data = action.payload?.result
        state.getAllMessages.offset = action.payload?.offset
        state.getAllMessages.limit = action.payload?.limit
        state.getAllMessages.count = action.payload?.count

        state.error = null
      })
      .addCase(receiveMessageAction.rejected, (state, action) => {
        state.receiveLoading = false
        state.receiveMessages = {}
        state.error = action.payload
      })
      // ** Receive mark Message Messages
      .addCase(markClientMessagesAction.pending, (state) => {
        state.markMessage = false
      })
      .addCase(markClientMessagesAction.fulfilled, (state, action) => {
        state.markMessage = true
        state.error = null
      })
      .addCase(markClientMessagesAction.rejected, (state, action) => {
        state.markMessage = false
        state.error = action.payload
      })
      // Get all messages
      .addCase(getAllMessagesAction.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllMessagesAction.fulfilled, (state, action) => {
        state.loading = false

        state.getAllMessages.data = action.payload?.result
        state.getAllMessages.offset = action.payload?.offset
        state.getAllMessages.limit = action.payload?.limit
        state.getAllMessages.count = action.payload?.count
      })
      .addCase(getAllMessagesAction.rejected, (state, action) => {
        action.payload.push()
        state.error = action.payload
      })
      // Get all conversations
      .addCase(getAllConversationsAction.fulfilled, (state, action) => {
        state.getAllConversations.data = action.payload
      })
      .addCase(getAllConversationsAction.rejected, (state, action) => {
        state.error = action.payload
      })
  }
})
export const { newMessagesHandler, resetReceiveMessages } =
  messagesReducer.actions
export default messagesReducer.reducer

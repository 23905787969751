/* eslint-disable no-unused-vars */
import useJwt from '@src/auth/jwt/useJwt'
import { createAsyncThunk } from '@reduxjs/toolkit'

// ** components
import toast from 'react-hot-toast'
import { Check, X } from 'react-feather'
import { ToastContent } from '@src/components/toast'

// Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name={key}
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.detail || errors[key][0] || errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}
//**   GetAllMeeting links */
export const getAllMeetings = createAsyncThunk(
  'teleHealth/getAllMeetings',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllMeetings()
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** generate meeting links
export const generateMeetingLinks = createAsyncThunk(
  'teleHealth/generateMeetingLinks',
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.generateMeeting(data)
      dispatch(getAllMeetings())
      if (response.data && callback) {
        callback()
      }
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Change Account
export const updateMeetingAccount = createAsyncThunk(
  'teleHealth/updateMeetingAccount',
  async ({ data, id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateMeetingAccount(data, id)
      if (response.data) {
        toast((t) => (
          <ToastContent
            t={t}
            name={'Account has been updated'}
            icon={<Check size={14} />}
            color="success"
            msg={response?.data?.msg}
          />
        ))
        callback()
      }
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Change Account
export const deleteConnectedAccount = createAsyncThunk(
  'teleHealth/deleteConnectedAccount',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.deleteConnectedAccount(id)

      toast((t) => (
        <ToastContent
          t={t}
          name={'Account has been deleted'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.msg}
        />
      ))
      callback()

      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const getTelehealthEmailTemplateAction = createAsyncThunk(
  'telehealth/getTelehealthEmailTemplate',
  async (param, { rejectWithValue }) => {
    try {
      const response = await useJwt.getTelehealthEmailTemplate()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const updateTelehealthTemplateAction = createAsyncThunk(
  'telehealth/updateTelehealthTemplate',
  async ({ data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateTelehealthTemplate(data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Telehealth template Updated Successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

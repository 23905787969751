import moment from 'moment'
export const columns = [
  {
    name: 'Date',
    minWidth: '10rem',
    cell: (row) => {
      return <span>{row?.createdAt ? row?.createdAt : '--'}</span>
    }
  },
  {
    name: 'Time',
    minWidth: '10rem',
    cell: (row) => {
      return (
        <span>
          {row?.events?.[0].time
            ? moment(row?.events?.[0].time, 'HH:mm').format('h:mm A')
            : '--'}
        </span>
      )
    }
  },
  {
    name: 'Event Details',
    cell: (row) => {
      return (
        <span>{row?.events?.[0].event ? row?.events?.[0].event : '--'}</span>
      )
    }
  }
]

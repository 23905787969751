import FormIconField from '@FormIconField'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'
import { Button, Label } from 'reactstrap'
import { Calendar } from 'react-feather'
import moment from 'moment'
const ActivityHeader = ({
  search,
  onSearchChange,
  startDate,
  endDate,
  onStartDateChangeHandler,
  onEndDateChangeHandler,
  onTimeChangeHandler
}) => {
  return (
    <div className="activity-header pt-3 p-2">
      <h3>Account Activity</h3>
      <div className="d-flex justify-content-between mt-2">
        <div className="left-container">
          <FormIconField
            id="Search"
            name="searchKeyword"
            size={20}
            maxLength={20}
            iconsName="ant-design:search-outlined"
            value={search}
            onChange={(e) => onSearchChange(e)}
            className="input-group-merge"
            inputClassName="input-control skin-change"
            iconClassName="icon-control skin-change"
          />
        </div>
        <div className="right-container d-flex">
          <Flatpickr
            data-enable-time
            id="startTime"
            name="startTime"
            type="time"
            placeholder="Time"
            className="radius-25 bg-white flat-picker-sm form-control select-w-70 skin-change"
            onChange={onTimeChangeHandler}
            options={{
              enableTime: true,
              noCalendar: true,

              dateFormat: 'h:i K'
            }}
          />
          <div
            className={classNames({
              'AppointmentSelectors_left-dates appointment-header--bottom_date d-f-center skin-change white-space': true
            })}
          >
            <Flatpickr
              id="start_date"
              name="datePicker"
              onChange={onStartDateChangeHandler}
              className="form-control datePicker-non-visible"
              options={{
                enableTime: false,
                dateFormat: 'F j, Y'
              }}
            />
            <Label
              htmlFor="start_date"
              className="mb-0 pointer d-flex align-items-center"
            >
              <Calendar size={20} color="#fff" />
              <strong className="fs-s-med">From : </strong>
              <span className="ml-5px fs-s-med">
                {moment(startDate).format('MM/DD/YYYY')}
              </span>
            </Label>
          </div>
          <div
            className={classNames({
              'AppointmentSelectors_left-dates appointment-header--bottom_date d-f-center skin-change white-space': true
            })}
          >
            <Flatpickr
              id="end_date"
              name="datePicker"
              onChange={onEndDateChangeHandler}
              className="form-control datePicker-non-visible"
              options={{
                enableTime: false,
                dateFormat: 'F j, Y'
              }}
            />
            <Label
              htmlFor="end_date"
              className="mb-0 pointer d-flex align-items-center"
            >
              <Calendar size={20} color="#fff" />
              <strong className="fs-s-med">To : </strong>
              <span className="ml-5px fs-s-med">
                {moment(endDate).format('MM/DD/YYYY')}
              </span>
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ActivityHeader

/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { X } from 'react-feather'
import toast from 'react-hot-toast'

// ** Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error While Sending Message"
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.msg ||
            errors?.detail ||
            errors[key] ||
            errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

//** send message */
export const markClientMessagesAction = createAsyncThunk(
  'messages/markClientMessages',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.markClientMessages(id)

      return response.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** send message */
export const receiveMessageAction = createAsyncThunk(
  'messages/receiveMessage',
  async ({ offset, limit, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.receiveMessage(offset, limit, id)
      dispatch(markClientMessagesAction({ id }))
      return response.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** send message */
export const sendMessageAction = createAsyncThunk(
  'messages/sendMessage',
  async ({ data, limit, id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.sendMessage(data)
      const recievedRes = await useJwt.receiveMessage(0, limit, id)
      if (callback) {
        callback()
      }

      return { ...response?.data, recievedRes: recievedRes.data }
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
// Get All Messages
export const getAllMessagesAction = createAsyncThunk(
  'messages/getAllMessages',
  async ({ id, limit }, { rejectWithValue }) => {
    try {
      const response = await useJwt.receiveMessage(0, limit, id)

      return response.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
// Get All Conversations
export const getAllConversationsAction = createAsyncThunk(
  'conversations/getAllConversations',
  async (param, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllConversations()

      return response.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

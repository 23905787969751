import FormIconField from '@FormIconField'
import Avatar from '@components/avatar'
import { SOCKET_MAIN_URL } from '@src/constants'
import defaultAvatar from '@src/assets/default.png'
import RobustWebSocket from 'robust-websocket'
import ChatBox from '../../components/screen.components/chat.screen/chat-box/chat-box'
import Select from 'react-select'
import { Icon } from '@iconify/react'
import { useCallback, useEffect, useState } from 'react'
import { Check, X } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { ToastContent } from '@src/components/toast'
import {
  getAllClientsAction,
  getClientAction
} from '../../redux/client/clientAction'
import { useQueryParams } from '../../utility/hooks/useQueryParams'
import EmptyChatBox from '../../components/screen.components/chat.screen/emptyChatBox/emptyChatBox'
import {
  getAllConversationsAction,
  getAllMessagesAction
} from '../../redux/message/messageAction'
import { newMessagesHandler } from '../../redux/message/messageSlice'

import CustomSpinner from '@spinner'
import { isJSON } from '../../utility/Utils'
const Option = (props) => {
  return (
    <div className="custom-option" {...props.innerProps} ref={props.innerRef}>
      <div className="text-capitalize">{props.data.label}</div>
      <div className="custom-option--div">{props.data.email}</div>
    </div>
  )
}
const Chat = () => {
  const [socket, setSocket] = useState(null)
  const [showChatBox, setShowChatBox] = useState(false)
  const allClients = useSelector(
    (state) => state.client.getAllClientsData.clientsList
  )
  const allConversations = useSelector(
    (state) => state.messages.getAllConversations.data
  )
  const query = useQueryParams()
  const allMessages = useSelector((state) => state.messages.getAllMessages)
  const loadingMessages = useSelector((state) => state.messages.loading)

  const loading = useSelector((state) => state.client.loading)
  const [client, setClient] = useState(null)

  const dispatch = useDispatch()

  const socketHandler = (socket) => {
    setSocket(socket)
  }
  const messagesHandler = (newMessage) => {
    dispatch(newMessagesHandler(newMessage))
  }

  const receiveMessagesHandler = (newMessage) => {
    messagesHandler(newMessage)
  }
  const buildConnection = useCallback(
    (token, providerId, clientId) => {
      const url = `${SOCKET_MAIN_URL}/ws/pr/room_${providerId}_${clientId}?token=${token}`
      const ws = new RobustWebSocket(url)
      ws.addEventListener('open', function () {})
      ws.addEventListener('message', function (event) {
        receiveMessagesHandler(JSON.parse(event.data))
      })
      ws.addEventListener('close', function () {})
      return ws
    },
    [client]
  )
  const sendMessagesHandler = (content) => {
    let newMessage

    if (content.files) {
      newMessage = {
        message: content.html,
        files: content.files,

        client: client.id,
        from_type: 0,
        created_at: Date.now() / 1000
      }
    } else {
      newMessage = {
        message: content.html,
        client: client.id,
        from_type: 0,
        created_at: Date.now() / 1000
      }
    }
    messagesHandler(newMessage)

    try {
      socket.send(JSON.stringify(newMessage))
    } catch (err) {}
  }
  const allClientsOptions = allClients.map((client) => {
    return {
      email: client.email,
      label: `${client.first_name} ${client.last_name}`,
      value: client.id
    }
  })

  const clientChangeHandler = ({ value }) => {
    const client = allClients.find((client) => client.id === value)

    if (client) {
      const newSocket = buildConnection(
        localStorage.getItem('accessToken') ||
          sessionStorage.getItem('accessToken'),
        JSON.parse(
          localStorage.getItem('userData') || sessionStorage.getItem('userData')
        ).user_id,
        client.id
      )
      socketHandler(newSocket)
      setClient(client)

      setShowChatBox(true)
      dispatch(
        getAllMessagesAction({ id: client.id, limit: allMessages.limit })
      )
    }
  }

  useEffect(() => {
    const clientId = query.get('navigate')
    if (clientId) {
      const newSocket = buildConnection(
        localStorage.getItem('accessToken') ||
          sessionStorage.getItem('accessToken'),
        JSON.parse(
          localStorage.getItem('userData') || sessionStorage.getItem('userData')
        ).user_id,
        clientId
      )
      socketHandler(newSocket)

      setShowChatBox(true)
      dispatch(getAllMessagesAction({ id: clientId, limit: 1000 }))

      dispatch(
        getClientAction({
          id: clientId,
          callback: (client) => {
            setClient(client)
          }
        })
      )
    }
  }, [query.get('navigate')])
  useEffect(() => {
    dispatch(getAllClientsAction({ offset: 0, limit: 1000 }))
  }, [])
  useEffect(() => {
    dispatch(getAllConversationsAction())
  }, [])
  return (
    <>
      <div className="chat-header-container">
        <div className="row chat-header">
          <div className="col-md-4">
            <div className="chat-sub-header d-flex align-items-center">
              <h4 className="chat-sidebar-heading m-0">Messages</h4>
            </div>
          </div>

          <div className="col-md-8">
            <div className="d-flex justify-content-between h-100">
              <div className="d-flex align-items-center column-gap-1">
                {client && (
                  <Avatar
                    imgHeight="40"
                    imgWidth="40"
                    imgClassName={'object-fit-cover'}
                    img={client?.avatar ? client?.avatar : defaultAvatar}
                  ></Avatar>
                )}
                <div className="flex-fill">
                  <div className="">
                    {client && <h6 className="m-0">{client?.first_name}</h6>}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 chat-sidebar">
            <div className="chat-sidebar-container">
              <h5 className="chat-small-heading">Message List</h5>
              <div className="search-container">
                <Select
                  isLoading={loading}
                  defaultValue={
                    allClients.length > 0 ? allClientsOptions[0] : null
                  }
                  components={{ Option }}
                  styles={{
                    option: (base) => ({
                      ...base,
                      backgroundColor: 'transparent',
                      padding: '0',
                      textTransform: 'capitalize',
                      color: '#ff0000',
                      '&:focus': {
                        backgroundColor: 'none'
                      },
                      '&:actice': {
                        backgroundColor: 'none'
                      }
                    })
                  }}
                  options={allClientsOptions}
                  onChange={clientChangeHandler}
                ></Select>
              </div>
              <ul className="chat-list">
                {allConversations.map((conversation) => {
                  return (
                    <li
                      className="chat-li cursor-pointer"
                      onClick={() => {
                        clientChangeHandler({ value: conversation.id })
                      }}
                    >
                      <div className="d-flex align-items-center column-gap-1">
                        <Avatar
                          imgHeight="40"
                          imgWidth="40"
                          imgClassName={'object-fit-cover'}
                          img={
                            conversation?.avatar
                              ? conversation?.avatar
                              : defaultAvatar
                          }
                        ></Avatar>
                        <div className="flex-fill">
                          <div className="d-flex justify-content-between">
                            <h6 className="m-0">
                              {conversation?.first_name}{' '}
                              {conversation?.last_name}
                            </h6>
                          </div>
                          {!isJSON(conversation?.message?.content) && (
                            <div
                              className="chat-mini-paragraph"
                              dangerouslySetInnerHTML={{
                                __html: conversation?.message?.content
                              }}
                            ></div>
                          )}
                          {isJSON(conversation?.message?.content) && (
                            <>
                              <p>
                                {
                                  JSON.parse(
                                    conversation?.message?.content
                                  )?.[0]?.fields.comment
                                }
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {showChatBox ? (
              loadingMessages ? (
                <CustomSpinner />
              ) : (
                <ChatBox
                  client={client}
                  socket={socket}
                  messages={allMessages?.data}
                  sendMessagesHandler={sendMessagesHandler}
                  socketHandler={socketHandler}
                />
              )
            ) : (
              <EmptyChatBox></EmptyChatBox>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Chat

// ** React Imports
import { Link, useNavigate } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { Icon } from '@iconify/react'

// ** Reactstrap Imports
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

// actions
import { handleLogout } from '../../../../redux/authentication/authSlice'
// ** Default Avatar Image
import defaultAvatar from '@src/assets/default.png'
import { useDispatch, useSelector } from 'react-redux'

const UserDropdown = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { getProviderProfile } = useSelector((state) => state.providers)
  const avatar2 = getProviderProfile?.avatar
  const avatar = useSelector((state) => state.auth?.user?.avatar)
  const profile_avatar = localStorage.getItem('avatar')
    ? localStorage.getItem('avatar')
    : null

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
        </div>
        <Avatar
          img={
            profile_avatar
              ? profile_avatar
              : !!avatar2
                ? avatar2
                : !!avatar
                  ? avatar
                  : defaultAvatar
          }
          imgHeight="40"
          imgWidth="40"
          imgClassName={'object-fit-cover'}
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem
          tag={Link}
          to="/settings/my-profile"
          onClick={() => navigate('/settings/my-profile')}
        >
          <Icon icon="bxs:user" className="me-75" />
          <span className="align-middle">
            <strong>Profile</strong>
          </span>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => dispatch(handleLogout())}
        >
          <Icon icon="entypo:log-out" className="me-75" />
          <span className="align-middle">
            <strong>Logout</strong>
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown

import { createSlice } from '@reduxjs/toolkit'

import { getAllTransactionHistoryAction } from './transactionHistoryAction'

const transactionHistoryReducer = createSlice({
  name: 'transactionHistory',
  initialState: {
    loading: false,

    getAllTransactionHistory: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    },

    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ** Get all activities
      .addCase(getAllTransactionHistoryAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllTransactionHistoryAction.fulfilled, (state, action) => {
        state.loading = false
        state.getAllTransactionHistory.data = action.payload?.result
        state.getAllTransactionHistory.offset = action.payload?.offset
        state.getAllTransactionHistory.limit = action.payload?.limit
        state.getAllTransactionHistory.count = action.payload?.count
      })
      .addCase(getAllTransactionHistoryAction.rejected, (state, action) => {
        state.loading = false
        state.getAllTransactionHistory = {
          data: [],
          offset: 0,
          limit: 10,
          total: 0
        }
        state.error = action.payload
      })
  }
})

export default transactionHistoryReducer.reducer

import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, Spinner } from 'reactstrap'
import themeConfig from '@configs/themeConfig'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useContext, useState } from 'react'
import { send2FaAuthCodeAction } from '../../redux/authentication/authAction'
import { AbilityContext } from '@src/utility/context/Can'
import { ToastContent } from '@src/components/toast'
import {
  ArrowDownLeft,
  ArrowLeft,
  ArrowRightCircle,
  Check,
  X
} from 'react-feather'
import toast from 'react-hot-toast'
import useJwt from '@src/auth/jwt/useJwt'
import OtpIcon from '../../assets/images/svg/OtpIcon'
import { Icon } from '@iconify/react/dist/iconify.js'
const OTPTimer = ({ initialTime = 60, onTimeUp }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime)

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)

      return () => clearInterval(timer) // Clean up the interval on component unmount
    } else if (timeLeft === 0) {
      onTimeUp() // Trigger when the timer reaches zero
    }
  }, [timeLeft, onTimeUp])

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`
  }

  return (
    <div style={{ marginTop: '15px' }}>
      <p>Time Left: {formatTime(timeLeft)}</p>
    </div>
  )
}

const EnterCode = () => {
  const { user, sendAuthCodeLoading } = useSelector((state) => state.auth)
  const [showResendCode, setShowResendCode] = useState(false)
  const [resendCodeLoading, setResendCodeLoading] = useState(false)
  const ability = useContext(AbilityContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const otpLength = 6
  const formik = useFormik({
    initialValues: { otp: Array(otpLength).fill('') },
    validate: (values) => {
      const errors = {}
      if (values.otp.some((digit) => digit === '')) {
        errors.otp = 'Please fill all fields'
      }
      return errors
    },
    onSubmit: (values, { resetForm }) => {
      const otpValue = values.otp.join('')
      const data = {
        otp: otpValue,
        email: user?.email
      }

      dispatch(
        send2FaAuthCodeAction({
          data,
          ability,
          navigate,
          callback: () => {
            resetForm()
          }
        })
      )
    }
  })

  const handleInputChange = async (event, index) => {
    const value = event.target.value
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...formik.values.otp]
      newOtp[index] = value
      formik.setFieldValue('otp', newOtp)
      if (value && index < otpLength - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus()
      }
    }
  }

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !formik.values.otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus()
    }
  }

  useEffect(() => {
    if (!user?.email && !user?.msg) {
      navigate('/login')
    }
  }, [user, navigate])

  if (!user?.email && !user?.msg) {
    return null
  }
  const handlePaste = async (event) => {
    const pastedData = event.clipboardData.getData('text')
    if (/^\d+$/.test(pastedData) && pastedData.length <= otpLength) {
      const newOtp = [...formik.values.otp]
      for (let i = 0; i < otpLength; i++) {
        newOtp[i] = pastedData[i] || '' // Fill each OTP input with the pasted value
      }
      formik.setFieldValue('otp', newOtp)
      // Automatically focus on the last filled input
      document
        .getElementById(
          `otp-input-${Math.min(pastedData.length, otpLength) - 1}`
        )
        .focus()
      // Auto-submit when a full OTP is pasted
    }
  }
  const handleTimeUp = () => {
    setShowResendCode(true)
  }
  const resendOtpHandler = async () => {
    if (resendCodeLoading) {
      return
    }
    try {
      setResendCodeLoading(true)
      const response = await useJwt.resend2FaAuthCode({ email: user?.email })
      toast((t) => (
        <ToastContent
          t={t}
          name="Success"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.msg}
        />
      ))
      setShowResendCode(false)
      setResendCodeLoading(false)
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error"
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      setResendCodeLoading(false)
    }
  }
  return (
    <>
      <Card className="auth-card">
        <ArrowLeft
          cursor={'pointer'}
          onClick={() => navigate('/login')}
          style={{ position: 'absolute', top: '20px', left: '20px' }}
        />
        <Link
          className="brand-logo pt-2"
          to="/"
          onClick={(e) => e.preventDefault()}
        >
          <OtpIcon />
        </Link>
        <div className="text-center">
          <form onSubmit={formik.handleSubmit}>
            <h5 className="pt-3">Sign in with your email</h5>
            <p className="mt-2">
              Please enter the 6-digit security code <br /> sent to{' '}
              <strong>{user?.email}</strong>.
            </p>
            <div className="pt-3" onPaste={handlePaste}>
              {formik.values.otp.map((digit, index) => (
                <input
                  className="otp-input-2"
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={formik.values.otp[index]}
                  onChange={(event) => handleInputChange(event, index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  maxLength={1}
                  style={{ marginRight: '15px' }}
                />
              ))}
            </div>
            {!showResendCode && (
              <div className="">
                <Button
                  className="button-continue pd "
                  disabled={sendAuthCodeLoading || !formik.dirty}
                >
                  {sendAuthCodeLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <Check size={14} />
                  )}
                  <span className="px-1">Continue</span>
                </Button>
              </div>
            )}

            {showResendCode && (
              <div className="">
                <Button
                  className="reset-otp-button pd "
                  onClick={resendOtpHandler}
                >
                  {resendCodeLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <Icon
                      icon="fa6-solid:arrows-rotate"
                      width="16"
                      height="16"
                    />
                  )}
                  <span className="px-1">Resend otp</span>
                </Button>
              </div>
            )}
            <div className="mt-3">
              <hr className="h1 mt-3" />
              {!showResendCode && (
                <OTPTimer initialTime={120} onTimeUp={handleTimeUp} />
              )}
            </div>
          </form>
        </div>
      </Card>
    </>
  )
}
export default EnterCode

/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** components
import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
import { formatPhoneNumber } from '../../utility/Utils'
import moment from 'moment'
// Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    if (Array.isArray(errors)) {
      errors.map((err) => {
        toast((t) => (
          <ToastContent
            t={t}
            name={err}
            icon={<X size={14} />}
            color="danger"
          />
        ))
      })
      return
    }
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name={key}
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.detail || errors[key][0] || errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

// ** Get All Providers List
export const getAllClientsAction = createAsyncThunk(
  'client/getAllClients',
  async (payload, { rejectWithValue }) => {
    const {
      offset,
      limit,
      startDate,
      endDate,
      search,
      status,
      sort,
      callback
    } = payload

    try {
      const response = await useJwt.getAllClients(
        offset,
        limit,
        startDate,
        endDate,
        search,

        status,
        sort
      )
      if (callback) {
        callback()
      }

      response?.data?.result?.forEach((element) => {
        element.phone_number = formatPhoneNumber(element.phone_number)
      })

      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Clients'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Provider
export const getClientAction = createAsyncThunk(
  'client/getClient',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getClient(id)
      if (response?.data?.phone_number) {
        response.data.phone_number = formatPhoneNumber(
          response?.data?.phone_number
        )
      }
      if (callback) {
        callback(response?.data)
      }
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Provider
export const getClientInvoiceAction = createAsyncThunk(
  'client/getClientInvoice',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getClientInvoice(id)
      callback()
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Message Details
export const getClientDetailsAction = createAsyncThunk(
  'client/getClientDetailsAction',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getClientDetails(id)

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = 'file.csv'
      document.body.appendChild(a)

      a.click()
      window.URL.revokeObjectURL(url)

      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Message Details
export const getMessageDetailsAction = createAsyncThunk(
  'client/getMessageDetailsAction',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getMessageDetails(id)

      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Upload Client Insurance Card  */
export const uploadInsuranceCardImageAction = createAsyncThunk(
  'client/uploadInsuranceCardImage',
  async ({ id, img, navigate, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.uploadInsuranceCardImage(id, img)
      callback(response?.data)
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error Upload Insurance Card"
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// **  Update Client Insurance Card **
export const updateClientImageAction = createAsyncThunk(
  'client/updateClientImage',
  async ({ id, img, navigate }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateClientImage(id, img)
      toast((t) => (
        <ToastContent
          t={t}
          name="Client Image Update Successfully"
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))

      navigate('/clients')
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Update Client
export const updateClientAction = createAsyncThunk(
  'client/updateClient',
  async ({ id, data, img, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.updateClient(id, data)
      if (!!(img && id)) {
        dispatch(updateClientImageAction({ id, img, navigate }))
      }

      if (response?.data && !(img && id)) {
        toast((t) => (
          <ToastContent
            t={t}
            name="Client Updated Successfully"
            icon={<Check size={14} />}
            color="success"
            msg={response?.data?.message}
          />
        ))
        navigate(`/clients/client/${id}?profile`)
        // callback()
      }

      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Add Client
export const registerClient = createAsyncThunk(
  'client/registerClient',
  async ({ data, img, navigate, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.registerClient(data)
      const id = response?.data?.id
      if (!!(img && id)) {
        const imageData = new FormData()
        imageData.append('image', img)

        dispatch(
          uploadInsuranceCardImageAction({
            id,
            img: imageData,
            navigate,
            callback: (res) => {
              if (res) {
                callback({
                  decoded,
                  first_name: data.first_name,
                  last_name: data.last_name
                })
              }
            }
          })
        )
      }

      if (response?.data && !(img && id)) {
        callback({
          id,
          first_name: data.first_name,
          last_name: data.last_name
        })
      }

      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get All Client Billings List
export const getAllClientBillingAction = createAsyncThunk(
  'client/getAllClientBilling',
  async (payload, { rejectWithValue }) => {
    const { offset, limit, status, client, startDate, endDate } = payload
    try {
      const response = await useJwt.getAllClientBilling(
        offset,
        limit,
        status,
        client,
        startDate,
        endDate
      )
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Providers'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const getAllClientBillingWithoutSuperbillAction = createAsyncThunk(
  'client/getAllClientBillingWithoutSuperbill',
  async (payload, { rejectWithValue }) => {
    const { offset, limit, status, client, startDate, endDate, super_bill } =
      payload
    try {
      const response = await useJwt.getAllClientBillingWithoutSuperbill(
        offset,
        limit,
        status,
        client,
        startDate,
        endDate
      )
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Providers'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Get Client Billings List
export const getClientBillingAction = createAsyncThunk(
  'client/getClientBilling',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getClientBilling(id)
      if (response?.data) {
        callback(response?.data)
      }
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Billing1324'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Client Billings List
export const updateClientBillingAction = createAsyncThunk(
  'client/updateClientBilling',
  async ({ id, data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateClientBilling(id, data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Billing updated Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Updating Billing'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Delete  Client
export const deleteClientAction = createAsyncThunk(
  'client/deleteClient',
  async ({ id, navigate, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.deleteClient(id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Client Deleted Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      navigate('/clients')
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Client Appointments
export const getClientAppointmentsAction = createAsyncThunk(
  'client/get-client-appointments',
  async ({ id, status, startDate, endDate, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getClientAppointments({
        id,
        status,
        endDate,
        startDate
      })
      callback()
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Appointments'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getClientNotesAction = createAsyncThunk(
  'client/client-notes',
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await useJwt.clientNotes()

      return fulfillWithValue({
        result: res.data.result.filter((element) => element.id !== 3) || []
      })
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Create Client Invoice */
export const createClientInvoiceAction = createAsyncThunk(
  'client/createClientInvoiceAction',
  async ({ data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.createClientInvoice(data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Invoice Created Successfully '}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      if (callback) {
        callback()
      }
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Create Invoice'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Client Appointments
export const deleteClientInvoiceAction = createAsyncThunk(
  'client/deleteClientInvoice',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.deleteClientInvoice(id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Invoice Deleted Successfully '}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Appointments'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get All Client Billings List
export const getAllClientDocumentsAction = createAsyncThunk(
  'client/getAllClientDocuments',
  async (payload, { rejectWithValue }) => {
    const { id } = payload
    try {
      const response = await useJwt.getAllClientDocuments(id)
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const retriveAllClientDocumentsAction = createAsyncThunk(
  'client/retriveAllClientDocuments',
  async (payload, { rejectWithValue }) => {
    const { id } = payload
    try {
      const response = await useJwt.retrivetAllClientDocuments(id)
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Register Client Documents
export const registerClientDocumentsAction = createAsyncThunk(
  'client/registerClientDocuments',
  async ({ data, id, callback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.registerClientDocuments(data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Client Document Registered Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      dispatch(retriveAllClientDocumentsAction({ id }))
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Delete Client Documents
export const deleteClientDocumentsAction = createAsyncThunk(
  'client/deleteClientDocuments',
  async ({ id, clientId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.deleteClientDocuments(id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Client Document Deleted Successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))
      dispatch(retriveAllClientDocumentsAction({ id: clientId }))
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

//**   get All Client Intake Form */

export const getAllIntakeFormAction = createAsyncThunk(
  'client/getAllIntakeForm',
  async ({ type, is_checked }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.getAllIntakeForm(type, is_checked)

      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

//**   Register Client Intake Form */

export const registerClientIntakeFormsAction = createAsyncThunk(
  'client/registerClientIntakeForms',
  async ({ data, callback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.registerClientIntakeForms(data)
      if (response.data) {
        toast((t) => (
          <ToastContent
            t={t}
            name={'Client Added Successfully'}
            icon={<Check size={14} />}
            color="success"
            msg={response?.data?.message}
          />
        ))
        callback()
      }
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** handle client appointments pagination
export const handleClientAppointmentsPagination = createAsyncThunk(
  'client/handle-client-appointments-pagination',
  async ({ id, offset, limit, startDate, endDate, status }, { dispatch }) => {
    dispatch(
      getClientAppointmentsAction({
        id,
        offset,
        limit,
        startDate,
        endDate,
        status,
        callback: () => {}
      })
    )
  }
)

export const handleClientAppointmentsLimitChange = createAsyncThunk(
  'client/client-appointments-limit-change',
  async (
    { id, oldLimit, newLimit, startDate, endDate, status },
    { dispatch, rejectWithValue }
  ) => {
    if (newLimit !== oldLimit) {
      dispatch(
        getClientAppointmentsAction({
          id,
          offset: 0,
          limit: newLimit,
          startDate,
          endDate,
          status,
          callback: () => {}
        })
      )
    }
  }
)

// ** Handling Pagination
export const handlePageChange = createAsyncThunk(
  'client/handlePageChange',
  async (
    {
      offset,
      limit,
      startDate,
      endDate,
      search,
      client,
      clients,
      status,
      superbill,
      sort
    },
    { dispatch }
  ) => {
    if (!superbill && !clients) {
      dispatch(
        getAllClientBillingAction({
          offset,
          limit,
          status,
          client,
          startDate,
          endDate,
          callback: () => {}
        })
      )
    } else if (superbill) {
      dispatch(
        getAllClientBillingWithoutSuperbillAction({
          offset: 0,
          limit: newLimit,
          status,
          client,
          startDate,
          endDate
        })
      )
    } else {
      dispatch(
        getAllClientsAction({
          offset,
          limit,
          status,
          startDate,
          endDate,
          search,
          sort,
          callback: () => {}
        })
      )
    }
  }
)

// ** Handling Limit
export const handleLimitChange = createAsyncThunk(
  'client/handleLimitChange',
  async (
    {
      oldLimit,
      newLimit,
      offset,
      search,
      startDate = '',
      endDate = '',
      client,
      clients = null,
      superbill,
      status,
      sort
    },
    { dispatch }
  ) => {
    if (newLimit !== oldLimit) {
      if (!superbill && !clients) {
        dispatch(
          getAllClientBillingAction({
            offset,
            limit: newLimit,
            status,
            client,
            startDate,
            endDate,
            callback: () => {}
          })
        )
      } else if (superbill) {
        dispatch(
          getAllClientBillingWithoutSuperbillAction({
            offset,
            limit: newLimit,
            status,
            client,
            startDate,
            endDate
          })
        )
      } else {
        dispatch(
          getAllClientsAction({
            offset,
            limit: newLimit,
            status,
            startDate,
            endDate,
            search,
            sort,
            callback: () => {}
          })
        )
      }
    }
  }
)

export const deleteAdminNoteAction = createAsyncThunk(
  'client/delete-admin-note',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const res = await useJwt.deleteAdminNote(id)

      if (res.status) {
        toast((t) => (
          <ToastContent
            t={t}
            name={'Admin note deleted successfully'}
            icon={<Check size={14} />}
            color="success"
          />
        ))
        callback()
      }
      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error deleting admin note'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Get Client Billings List
export const updateClientAdminNoteAction = createAsyncThunk(
  'client/update-admin-note',
  async ({ id, data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateAdminNote(id, data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Admin note update Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback(response?.data)
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Updating Admin note'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

//**   Get Billing Invoice */
export const BillingInvoiceAction = createAsyncThunk(
  'client/BillingInvoiceAction',
  async ({ data, id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.BillingInvoice(data, id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Email invoice sent Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.response}
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Billings'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      callback()
      return rejectWithValue(err?.response?.data)
    }
  }
)

//**   Generate Super bill Invoice */
export const generateSuperBillingInvoiceAction = createAsyncThunk(
  'client/generateSuperBillingInvoiceAction',
  async ({ data, id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.generateSuperBillingInvoice(data, id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Email invoice sent Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.response}
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error while Sending Superbill Invoice'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      callback()
      return rejectWithValue(err?.response?.data)
    }
  }
)

//**   Generate Super  Billing Invoice */
export const SuperBillingInvoiceAction = createAsyncThunk(
  'client/SuperBillingInvoiceAction',
  async ({ id, data, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.SuperBillingInvoice(data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Superbill Invoice Created  Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.response}
        />
      ))
      callback()
      const currentDate = moment()
      const today = moment(currentDate).startOf('month').toDate()
      const _30days = moment(currentDate).endOf('month').toDate()
      dispatch(
        getAllClientBillingAction({
          offset: 0,
          limit: 10,

          client: id,
          startDate: today,
          endDate: _30days
        })
      )
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error While Creating Superbill'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const editSuperBillInvoiceAction = createAsyncThunk(
  'client/editSuperBillInvoiceAction',
  async ({ data, id, clientId, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.editSuperBillInvoice({ data, id })
      toast((t) => (
        <ToastContent
          t={t}
          name={'Superbill Invoice Edited  Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.response}
        />
      ))
      if (callback) {
        callback()
      }
      const currentDate = moment()
      const today = moment(currentDate).startOf('month').toDate()
      const _30days = moment(currentDate).endOf('month').toDate()
      dispatch(
        getAllClientBillingAction({
          offset: 0,
          limit: 10,

          client: clientId,
          startDate: today,
          endDate: _30days
        })
      )

      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error While Editing Superbill'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const deleteSuperBillInvoiceAction = createAsyncThunk(
  'client/deleteSuperBillInvoiceAction',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const res = await useJwt.deleteSuperBillInvoice(id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Superbill Invoice  deleted successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))
      callback()
      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error deleting superbill invoice'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getSuperBillInvoiceAction = createAsyncThunk(
  'client/getSuperBillInvoiceAction',
  async ({ id, callback }, { rejectWithValue }) => {
    try {
      const res = await useJwt.getSuperBillInvoice(id)

      if (res.status) {
        callback()
      }
      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Superbill Invoice'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const exportClientDetailsAction = createAsyncThunk(
  'client/exportClientDetailsAction',
  async (id, { rejectWithValue }) => {
    try {
      const res = await useJwt.exportClientDetails(id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Yout export is sent to your email successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))

      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error exporting documents'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

import { useState, useEffect, useRef } from 'react'
import { Icon } from '@iconify/react'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import TextEditor from '@src/components/editor'
import ChatFilePreview from '../chat-file-preview/chat-file-preview'
import { sendChatFileAction } from '../../../../redux/chat/chatAction'
import { useSelector, useDispatch } from 'react-redux'
import { resetFilesHandler } from '../../../../redux/chat/chatSlice'
import { isJSON } from '../../../../utility/Utils'
import {useSkin} from '@hooks/useSkin'
const isEditorStateEmpty = (editorState) => {
  const contentState = editorState.getCurrentContent()
  return (
    contentState.hasText() === false ||
    (contentState.getBlockMap().size === 1 &&
      contentState.getFirstBlock().getType() === 'unstyled' &&
      contentState.getFirstBlock().getText() === '')
  )
}
const ChatBox = ({ messages, sendMessagesHandler, client }) => {
  const fileData = useSelector((state) => state.chatFile.fileData)
  const loading = useSelector((state) => state.chatFile.loading)
  const inputFileRef = useRef()
  const chatContainerRef = useRef()
  const [value, setValue] = useState(() => EditorState.createEmpty())
  const [file, setFile] = useState(null)
  const [fileDataURL, setFileDataURL] = useState('')
  const [fileType, setFileType] = useState('')
  const dispatch = useDispatch()
  const {skin} = useSkin()
  const fileChangeHandler = (e) => {
    if (e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]

    let fileType
    const filesFormat = ['png', 'jpeg', 'jpg', 'pdf']

    filesFormat.forEach((format) => {
      if (file.type.includes(format)) {
        fileType = format
      }
    })
    if (!fileType) {
      alert('Please select only images (jpg, jpeg, png) or PDF files.')
      e.preventDefault()
      return
    }

    setFileType(fileType)
    setFile(file)
    const formData = new FormData()
    formData.append('client', client.id)
    formData.append('files', file)
    dispatch(sendChatFileAction({ data: formData }))
  }
  const removeFileHandler = () => {
    setFile(null)
    inputFileRef.current.value = null
    dispatch(resetFilesHandler())
  }
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current

      container.scrollTop = container.scrollHeight
    }
  }
  useEffect(() => {
    scrollToBottom()
  }, [messages])
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  return (
    <div className="chat-main-container pb-2 d-flex flex-column">
      <div ref={chatContainerRef} className="chatbox flex-grow-1 pt-2 pb-2">
        {messages.map((element) => {
          if (element.from_type === 0) {
            return (
              <div class="d-flex sender justify-content-end">
                <div
                  style={{
                    width: '50%',
                    background: skin === 'dark' ? '#242b3d' : '#fbf0d5',
                    borderRadius: '8px',
                    padding: '10px'
                  }}
                >
                  {!isJSON(element.message) && !isJSON(element.content) && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: element.message || element.content
                      }}
                    ></div>
                  )}
                  {isJSON(element.content) && (
                    <div
                      style={{
                        width: '50%',
                        background: skin === 'dark' ? '#242b3d' : '#cfd8e5',
                        borderRadius: '8px',
                        padding: '10px'
                      }}
                    >
                      {JSON.parse(element.content)?.[0].fields.comment}
                    </div>
                  )}

                  {element.files?.[0]?.file_url && (
                    <a href={element.files?.[0]?.file_url} target="_blank">
                      <ChatFilePreview
                        messagePreview={true}
                        file={element.files?.[0]?.file_url}
                        fileName={element.files?.[0]?.file_name}
                        fileType={element.files?.[0]?.file_name
                          .split('.')
                          .pop()}
                      />
                    </a>
                  )}
                </div>
              </div>
            )
          } else {
            return (
              <div class="d-flex receiver justify-content-start">
                {!isJSON(element.message) && !isJSON(element.content) && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.message || element.content
                    }}
                    style={{
                      width: '50%',
                      background: skin === 'dark' ? '#343d55' : '#cfd8e5',
                      borderRadius: '8px',
                      padding: '10px'
                    }}
                  ></div>
                )}
                {isJSON(element.content) && (
                  <div
                    style={{
                      width: '50%',
                      background: '#cfd8e5',
                      borderRadius: '8px',
                      padding: '10px'
                    }}
                  >
                    {JSON.parse(element.content)?.[0].fields.comment}
                  </div>
                )}
                {element.files?.[0]?.file_url && (
                  <a href={element.files?.[0]?.file_url} target="_blank">
                    <ChatFilePreview
                      file={element.files?.[0]?.file_url}
                      fileName={element.files?.[0]?.file_name}
                      fileType={element.files?.[0]?.file_name.split('.').pop()}
                    />
                  </a>
                )}
              </div>
            )
          }
        })}
      </div>
      <div className="input-container">
        <div className="h-100 input-sub-container">
          <div className="text-editor-container">
            <TextEditor
              value={value}
              setValue={setValue}
              toolbar={{
                options: ['inline', 'list', 'history'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic'],
                  bold: { className: 'toolbarClassName--text' },
                  italic: { className: 'toolbarClassName--text' }
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['unordered', 'ordered'],
                  unordered: { className: 'toolbarClassName--text' },
                  ordered: { className: 'toolbarClassName--text' }
                }
              }}
            />
          </div>
          <div className="mt-1">
            {fileDataURL && file && (
              <ChatFilePreview
                file={fileDataURL}
                fileName={file?.name}
                fileType={fileType}
                loading={loading}
                removeFileHandler={removeFileHandler}
              />
            )}
          </div>

          <div className="d-flex justify-content-between mt-1">
            <div className="input-container--left-container">
              <div className="file-input-container">
                <label htmlFor="file">
                  <Icon
                    icon="ion:attach"
                    width="20"
                    height="20"
                    style={{ cursor: 'pointer' }}
                  />
                </label>
                <input
                  id="file"
                  type="file"
                  className="d-none"
                  onChange={fileChangeHandler}
                  accept=".jpg,.jpeg,.png,.gif,.pdf"
                  ref={inputFileRef}
                ></input>
              </div>
            </div>
            <div className="input-container--right-container">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (isEditorStateEmpty(value) && !fileData) {
                    return
                  }
                  setValue(() => EditorState.createEmpty())
                  const html = draftToHtml(
                    convertToRaw(value.getCurrentContent())
                  )
                  removeFileHandler()
                  sendMessagesHandler({ html, files: fileData })
                }}
              >
                <Icon
                  icon="mingcute:send-fill"
                  width="20"
                  height="20"
                  style={{ color: '#6b9879' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChatBox

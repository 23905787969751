import FileViewer from 'react-file-viewer'
import { Spinner } from 'reactstrap'
import { Icon } from '@iconify/react'
const ChatFilePreview = ({
  file,
  fileType,
  loading,
  removeFileHandler,
  messagePreview
}) => {
  const filesFormat = ['png', 'jpeg', 'jpg']

  return (
    <div className="chat-file-preview">
      {loading && (
        <div className="">
          <Spinner size="sm" />
        </div>
      )}
      {!loading && !messagePreview && (
        <div onClick={removeFileHandler} className="cursor-pointer">
          <Icon icon="ic:round-cancel" width="20" height="20" />
        </div>
      )}
      {filesFormat.includes(fileType) && (
        <img
          src={file}
          alt="image"
          width={200}
          className="chat-file-preview-image"
        ></img>
      )}
      {fileType === 'pdf' && (
        <div className="pdf-previewer d-flex">
          <div className="pdf-previewer--icon">
            <Icon
              icon="mingcute:pdf-fill"
              width="60"
              height="60"
              style={{ color: 'black' }}
            />
          </div>
          <div>
            <h4 className="text-capitalize mb-0">{fileType}</h4>
          </div>
        </div>
      )}
    </div>
  )
}
export default ChatFilePreview

/* eslint-disable no-unused-vars */
// ** React Imports
import { Fragment, useEffect } from 'react'

// ** Custom Components

// ** Third Party Components
import classnames from 'classnames'
import { Bell } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
// ** Reactstrap Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import {
  getAllNotificationAction,
  markAllNotificationAction
} from '../../../../redux/notification/notificationAction'

const NotificationDropdown = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getAllNotification, loading, markAllLoading } = useSelector(
    (state) => state.notification
  )
  const rows = getAllNotification?.data
  useEffect(() => {
    dispatch(getAllNotificationAction({ offset: 0, limit: 10 }))
  }, [])

  const unreadNotificationNumber = rows?.filter(
    (item) => item.read === false
  )?.length

  // ** Function to render Notifications
  /*eslint-disable */
  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false
        }}
      >
        {rows?.length > 0 &&
          rows.map((item, index) => {
            return (
              <a
                key={index}
                className="d-flex"
                onClick={() => {
                  if (item.client) {
                    navigate(`/chat?navigate=${item.client}`)
                    return
                  }
                }}
                style={{ fontWeight: !item.read ? 'bold' : '' }}
              >
                <div
                  className={classnames('list-item d-flex', {
                    'align-items-start': !item.switch,
                    'align-items-center': item.switch
                  })}
                >
                  {!item.switch ? (
                    <Fragment>
                      <div className="list-item-body flex-grow-1">
                        {item.content}
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {item.content}
                    </Fragment>
                  )}
                </div>
              </a>
            )
          })}
      </PerfectScrollbar>
    )
  }
  /*eslint-enable */
  const handleReadAllNotification = () => dispatch(markAllNotificationAction())
  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-notification nav-item me-25"
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        <Bell color="#4b4b4b" size={21} />
        {unreadNotificationNumber > 0 && (
          <Badge pill color="danger" className="badge-up">
            {unreadNotificationNumber}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu
        end
        tag="ul"
        className="dropdown-menu-media mt-0  margin-top-sm-screen"
      >
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex align-items-center" tag="div" header>
            <h4 className="notification-title mb-0">Notifications</h4>
            {unreadNotificationNumber > 0 && (
              <Badge tag="div" color="light-primary" className="ml-1" pill>
                {unreadNotificationNumber} New
              </Badge>
            )}
            <div
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={handleReadAllNotification}
            >
              Mark All as Read
            </div>
          </DropdownItem>
        </li>
        {rows?.length > 0 ? (
          <>
            {renderNotificationItems()}
            <li className="dropdown-menu-footer d-flex justify-content-between align-items-center">
              <Button
                color="primary"
                onClick={() => navigate('/notifications')}
              >
                See All Notifications
              </Button>
              <Icon
                icon="ci:settings-filled"
                className="cursor-pointer"
                width="20"
                height="20"
                onClick={() => navigate('/settings/notifications')}
              />
            </li>
          </>
        ) : (
          <p className="center p-2">There are no notifications at the moment</p>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NotificationDropdown

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteConnectedAccount,
  generateMeetingLinks,
  getAllMeetings,
  getTelehealthEmailTemplateAction,
  updateTelehealthTemplateAction,
  updateMeetingAccount
} from './teleHealthAction'

export const TeleHealthReducer = createSlice({
  name: 'teleHealth',
  initialState: {
    generateMeeting: null,
    getAllMeetingData: null,
    teleHealthEmailTemplate: null,
    isDeleteLoading: false,
    updateTelehealthTemplatePending: false,
    updateMeetingPending: false,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    const success = (state) => {
      state.loading = false
      state.error = null
    }

    const error = (state, action) => {
      state.loading = false
      state.error = action.payload
    }

    builder
      // ** Generate Meeting
      .addCase(generateMeetingLinks.pending, (state) => {
        state.loading = true
      })
      .addCase(generateMeetingLinks.fulfilled, (state, action) => {
        success(state)

        state.generateMeeting = action.payload
      })
      .addCase(generateMeetingLinks.rejected, (state, action) => {
        error(state, action)
        state.generateMeeting = null
      })

      // ** Get All Meetings
      .addCase(getAllMeetings.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllMeetings.fulfilled, (state, action) => {
        success(state)
        state.getAllMeetingData = action.payload
      })
      .addCase(getAllMeetings.rejected, (state, action) => {
        error(state, action)
        state.getAllMeetingData = null
      })
      // ** delete Account
      .addCase(deleteConnectedAccount.pending, (state) => {
        state.isDeleteLoading = true
      })
      .addCase(deleteConnectedAccount.fulfilled, (state, action) => {
        state.isDeleteLoading = false
      })
      .addCase(deleteConnectedAccount.rejected, (state, action) => {
        state.isDeleteLoading = false
      })
      .addCase(getTelehealthEmailTemplateAction.fulfilled, (state, action) => {
        state.teleHealthEmailTemplate = action.payload
        state.error = null
      })
      .addCase(getTelehealthEmailTemplateAction.rejected, (state, action) => {
        state.teleHealthEmailTemplate = null
        state.error = action.payload
      })
      .addCase(updateTelehealthTemplateAction.pending, (state) => {
        
        state.updateTelehealthTemplatePending = true
      })
      .addCase(updateTelehealthTemplateAction.fulfilled, (state, action) => {
       
        state.updateTelehealthTemplatePending = false
        state.teleHealthEmailTemplate = action.payload

        state.error = null
      })
      .addCase(updateTelehealthTemplateAction.rejected, (state, action) => {
        state.updateTelehealthTemplatePending = false

        state.error = action.payload
      }).addCase(updateMeetingAccount.pending, (state) => {
        
        state.updateMeetingPending = true
      })
      .addCase(updateMeetingAccount.fulfilled, (state, action) => {
       
        state.updateMeetingPending = false
        
      })
      .addCase(updateMeetingAccount.rejected, (state, action) => {
        state.updateMeetingPending = false

        
      })
  }
})

export default TeleHealthReducer.reducer

/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// components
// ** components
import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
import { getClientAppointmentsAction } from '../clientAction'

// Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name={key}
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.detail || errors[key][0] || errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

export const getAllAdminNotesAction = createAsyncThunk(
  'adminNotes/get-all-admin-notes',
  async ({ offset, limit }, { rejectWithValue }) => {
    try {
      const res = await useJwt.getAllAdminNotes(offset, limit)

      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Something went wrong with get all admin notes'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getAdminNoteByIdAction = createAsyncThunk(
  'adminNotes/get-admin-note-by-id',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await useJwt.getAdminNoteById(id)

      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Something went wrong'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
    }
  }
)

export const addAdminNoteAction = createAsyncThunk(
  'adminNotes/add-admin-note',
  async ({ data, commonParams, callback }, { dispatch, rejectWithValue }) => {
    try {
      const res = await useJwt.addAdminNote(data)
      if (res?.status === 201) {
        dispatch(getClientAppointmentsAction(commonParams))
        callback()
        toast((t) => (
          <ToastContent
            t={t}
            name={'Admin note added successfully'}
            icon={<Check size={14} />}
            color="success"
          />
        ))
      }
      return res?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateAdminNoteAction = createAsyncThunk(
  'adminNotes/update-admin-note',
  async ({ id, clientId }, { rejectWithValue }) => {
    try {
      const res = await useJwt.updateAdminNote(id, clientId)

      if (res.data) {
        toast((t) => (
          <ToastContent
            t={t}
            name={'Admin note updated successfully'}
            icon={<Check size={14} />}
            color="success"
          />
        ))
      }
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error updating admin note'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
    }
  }
)

export const deleteAdminNoteAction = createAsyncThunk(
  'adminNotes/delete-admin-note',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await useJwt.deleteAdminNote(id)

      if (res.status) {
        toast((t) => (
          <ToastContent
            t={t}
            name={'Admin note deleted successfully'}
            icon={<Check size={14} />}
            color="success"
          />
        ))
      }
      return res?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error deleting admin note'}
          icon={<X size={14} />}
          color="danger"
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

export const sendChatFileAction = createAsyncThunk(
  'chat/sendChatFile',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await useJwt.sendChatFile(data)

      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { sendChatFileAction } from './chatAction'
const ChatFileReducer = createSlice({
  name: 'chat-file',
  initialState: {
    error: null,
    loading: false,
    fileData: null
  },
  reducers: {
    resetFilesHandler: (state) => {
      state.fileData = null
    }
  },
  extraReducers: (builder) => {
    builder

      // ** Send Messages
      .addCase(sendChatFileAction.pending, (state) => {
        state.loading = true
      })
      .addCase(sendChatFileAction.fulfilled, (state, action) => {
        state.loading = false
        state.fileData = action.payload?.files
      })
      .addCase(sendChatFileAction.rejected, (state, action) => {
        state.loading = false
        state.sendMessages = {}
        state.error = action.payload
      })
  }
})
export const { resetFilesHandler } = ChatFileReducer.actions
export default ChatFileReducer.reducer

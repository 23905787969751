/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'

// ** Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error While Updating Invoice"
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.msg ||
            errors?.detail ||
            errors[key] ||
            errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

//** Get invoice  */
export const getInvoiceAction = createAsyncThunk(
  'invoice/getInvoice',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getInvoice(id)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Update invoice  */
export const updateInvoiceAction = createAsyncThunk(
  'invoice/updateInvoice',
  async ({ id, data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateInvoice(id, data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Invoice Updated Successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Get Superbills  */
export const getSuperBillsAction = createAsyncThunk(
  'invoice/getSuperBills',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getSuperBills(id)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Update invoice  */
export const updateSuperBillsAction = createAsyncThunk(
  'invoice/updateSuperBills',
  async ({ id, data, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateSuperBills(id, data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Superbill Updated Successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))
      callback()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Update invoice  */
export const deleteInvoiceImageAction = createAsyncThunk(
  'invoice/deleteInvoiceImage',
  async ({ superbill, id, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.deleteInvoiceImage(superbill, id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Image Delete Successfully'}
          icon={<Check size={14} />}
          color="success"
        />
      ))
      callback(superbill)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import {
  deleteProviderImageAction,
  updateProviderImageAction
} from '../setting/management/myProfile/myProfileActions'
import {
  ResetPasswordAction,
  createNewPasswordAction,
  forgotPasswordAction,
  login,
  send2FaAuthCodeAction
} from './authAction'

const initialUser = () => {
  const item = useJwt.getUserData()
  return item ? item : {}
}

// Reducers
export const AuthReducer = createSlice({
  name: 'auth',
  initialState: {
    loginInProgress: false,
    passwordLoading: false,
    forgotPasswordLoading: false,
    resetPasswordLoading: false,
    user: initialUser(),
    sendAuthCodeLoading: false
  },
  reducers: {
    updateUser: (state) => {
      const userData = JSON.parse(localStorage.getItem('userData'))

      state.user = userData
    },
    handleLogout: (state) => {
      state.user = {}
      localStorage.clear()
    }
  },
  extraReducers: (builder) => {
    builder
      //**   Login */
      .addCase(login.pending, (state) => {
        state.loginInProgress = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginInProgress = false
        state.error = null
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.loginInProgress = false
        state.error = action.payload
        state.user = null
      })

      //**  Create New Password   */
      .addCase(createNewPasswordAction.pending, (state) => {
        state.passwordLoading = true
      })
      .addCase(createNewPasswordAction.fulfilled, (state, action) => {
        state.passwordLoading = false
        state.error = null
      })
      .addCase(createNewPasswordAction.rejected, (state, action) => {
        state.passwordLoading = false
        state.error = action.payload
      })

      //**  forgot Password   */
      .addCase(forgotPasswordAction.pending, (state) => {
        state.forgotPasswordLoading = true
      })
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false
        state.error = null
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.forgotPasswordLoading = false
        state.error = action.payload
      })

      /**  forgot Password   */
      .addCase(ResetPasswordAction.pending, (state) => {
        state.resetPasswordLoading = true
      })
      .addCase(ResetPasswordAction.fulfilled, (state, action) => {
        state.resetPasswordLoading = false
        state.error = null
      })
      .addCase(ResetPasswordAction.rejected, (state, action) => {
        state.resetPasswordLoading = false
        state.error = action.payload
      })

      .addCase(updateProviderImageAction.fulfilled, (state, action) => {
        state.user.avatar = action.payload.avatar
      })

      .addCase(deleteProviderImageAction.fulfilled, (state, action) => {
        state.user = { ...state.user, avatar: null }
      })
      .addCase(send2FaAuthCodeAction.pending, (state) => {
        state.sendAuthCodeLoading = true
      })
      .addCase(send2FaAuthCodeAction.fulfilled, (state, action) => {
        state.sendAuthCodeLoading = false
        state.user = action.payload
      })
      .addCase(send2FaAuthCodeAction.rejected, (state, action) => {
        state.sendAuthCodeLoading = false
      })
  }
})

export const { updateUser, handleLogout } = AuthReducer.actions

export default AuthReducer.reducer

import { createSlice } from '@reduxjs/toolkit'
import {
  getProviderCalendarAction,
  updateProviderCalendarAction,
  getAppointmentEmailTemplateAction,
  updateAppointmentEmailTemplateAction
} from './providerCalendarAction'

export const providerCalendarReducer = createSlice({
  name: 'providerCalendar',
  initialState: {
    error: null,
    loading: false,
    updateLoading: false,
    getProviderCalendar: null,
    updateProviderCalendar: null,
    appointmentEmailTemplate: null,
    updateAppointmentTemplatePending: false
  },
  reducers: {},

  extraReducers: (builder) => {
    builder

      // ** Get provider calendar
      .addCase(getProviderCalendarAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getProviderCalendarAction.fulfilled, (state, action) => {
        state.loading = false
        state.getProviderCalendar = action.payload
        state.error = null
      })
      .addCase(getProviderCalendarAction.rejected, (state, action) => {
        state.loading = false
        state.getProviderCalendar = {}
        state.error = action.payload
      })

      // ** update calendar
      .addCase(updateProviderCalendarAction.pending, (state) => {
        state.updateLoading = true
      })
      .addCase(updateProviderCalendarAction.fulfilled, (state, action) => {
        state.updateLoading = false
        state.getProviderCalendar = action.payload
        state.updateProviderCalendar = action.payload
        state.error = null
      })
      .addCase(updateProviderCalendarAction.rejected, (state, action) => {
        state.updateLoading = false
        state.updateProviderCalendar = {}
        state.error = action.payload
      })
      .addCase(getAppointmentEmailTemplateAction.fulfilled, (state, action) => {
        state.appointmentEmailTemplate = action.payload
        state.error = null
      })
      .addCase(getAppointmentEmailTemplateAction.rejected, (state, action) => {
        state.appointmentEmailTemplate = null
        state.error = action.payload
      })
      .addCase(updateAppointmentEmailTemplateAction.pending, (state) => {
        state.updateAppointmentTemplatePending = true
      })
      .addCase(
        updateAppointmentEmailTemplateAction.fulfilled,
        (state, action) => {
          state.updateAppointmentTemplatePending = false
          state.appointmentEmailTemplate = action.payload

          state.error = null
        }
      )
      .addCase(
        updateAppointmentEmailTemplateAction.rejected,
        (state, action) => {
          state.updateAppointmentTemplatePending = false

          state.error = action.payload
        }
      )
  }
})

export default providerCalendarReducer.reducer

/* eslint-disable no-unused-vars */

// ** Third Party Components
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'

const TextEditor = ({ value, setValue, toolbar }) => {
  // ** State


  return (
    <>
      <Editor
        toolbar={toolbar}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={value}
        onEditorStateChange={(data) => setValue(data)}
      />
    </>
  )
}

export default TextEditor

TextEditor.propTypes = {
  toolbar: PropTypes.object
}

import { Icon } from '@iconify/react'
import React from 'react'

const NotFound = ({ noShow = false, search = true }) => {
  return noShow ? null : (
    <div
      className="react-dataTable d-flex align-items-center justify-content-center"
      style={{ minHeight: '20vh' }}
    >
      <div className="mb-1 d-flex flex-column align-items-center justify-content-center">
        <Icon
          className="mb-1"
          icon="material-symbols:search-rounded"
          width="50"
          height="50"
        />
        {search && <h4>Search for result</h4>}
        <h5>No result found </h5>
      </div>
    </div>
  )
}

export default NotFound
